.footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .footer p {
    font-size: 14px !important; /* Increase specificity with !important */
    margin-bottom: 5px;
  }
  
  .logo {
    width: 150px; /* Keep the logo bigger */
    height: auto;
  }