div.HomePage {
    text-align: center;
    padding: 40px;
    max-width: 600px;
    margin: 50px auto;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  div.HomePage h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  div.HomePage p {
    font-size: 1.2em;
    margin-top: 20px;
    line-height: 1.5;
  }
  
  div.HomePage button {
    padding: 15px 30px;
    margin-top: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s;
  }
  
  div.HomePage button:hover {
    background-color: #0056b3;
  }

  .footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .footer p {
    font-size: 14px !important; /* Increase specificity with !important */
    margin-bottom: 5px;
  }
  
  .logo {
    width: 150px; /* Keep the logo bigger */
    height: auto;
  }
  
  
  
  /* Add a media query for mobile screens */
  @media (max-width: 600px) {
    .HomePage {
      margin-top: 10px; /* Reduce the margin on top for mobile */
      padding: 10px; /* Optionally, reduce padding if needed */
    }
  }
 