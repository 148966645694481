.GameOverScreen {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .GameOverScreen h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .GameOverScreen h2 {
    font-size: 1.8em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .GameOverScreen p {
    font-size: 1.2em;
    color: #666;
    margin: 10px 0;
  }
  
  .GameOverScreen input {
    padding: 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 80%;
    max-width: 300px;
    margin-bottom: 15px; /* Add spacing below the input */
  }
  
  /* Container for the input and button */
  .formContainer {
    display: flex;
    flex-direction: column; /* Stack the input and button vertically */
    align-items: center;
  }
  
  .GameOverScreen button {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    width: 200px;
  }
  
  .GameOverScreen button:hover {
    background-color: #333;
  }

  .GameOverScreen ol {
    margin: 20px auto;
    padding: 0;
    list-style-type: decimal; /* Ensure numbers are shown */
    text-align: center; /* Center the text content */
  }
  
  .GameOverScreen li {
    font-size: 1.2em;
    color: #444;
    margin: 5px 0;
    list-style-position: inside; /* Keeps the numbers inside and aligned with the text */
  }
  