.AudioChallenge {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .AudioChallenge h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .AudioChallenge h2 {
    font-size: 1.5em;
    color: #555;
    margin-bottom: 10px;
  }
  
  .AudioChallenge p {
    font-size: 1.1em;
    color: #666;
    margin: 15px 0;
  }
  
  .AudioPlayer {
    margin: 20px 0;
  }
  
  .AudioChallenge .play-button {
    width: 100px;
    height: 50px; /* Smaller height */
    margin-bottom: 20px; /* Added spacing below */
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em; /* Smaller text */
  }
  
  .AudioChallenge .play-button:hover {
    background-color: #333;
  }
  
  .AudioChallenge textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .AudioChallenge button {
    padding: 10px 20px; /* Smaller padding for smaller button */
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em; /* Smaller text */
    width: 100%;
    max-width: 250px; /* Adjusted max-width */
    margin-top: 10px; /* Add margin for spacing */
  }
  
  .AudioChallenge button:hover {
    background-color: #333;
  }

  .playButton {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    width: 100px; /* Smaller size */
    height: 40px;
    margin-bottom: 20px; /* Space below the play button */
  }
  
  .playButton:hover {
    background-color: #333;
  }
  
  .submitButton {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    width: 150px; /* Smaller size */
    height: 40px;
    margin-top: 10px; /* Space above the submit button */
  }
  
  .submitButton:hover {
    background-color: #333;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .AudioChallenge textarea {
    width: 100%; /* Ensure the textarea takes full width of the container */
    max-width: 100%; /* Prevent the textarea from exceeding the container width */
    padding: 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin-bottom: 20px;
  }

  
  